<template>
  <div class="container mb-48">
    <div
      class="
        w-full
        h-full
        flex
        items-center
        justify-items-center justify-center
      "
    >
      <div class="w-full h-full flex flex-wrap">
        <div class="w-full lg:w-1/2 mt-12 mb-4">
          <div
            class="h-full flex items-center justify-center justify-items-center"
          >
            <div class="flex flex-wrap w-full">
              <div
                class="
                  w-full
                  mb-12
                  flex
                  items-center
                  justify-center justify-items-center
                "
              >
                <img
                  class="object-contain"
                  src="@/assets/mog/login1.svg"
                  alt=""
                />
              </div>

              <div
                class="
                  w-full
                  flex
                  items-center
                  justify-center justify-items-center
                "
              >
                <img
                  class="object-contain"
                  src="@/assets/mog/login2.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full h-full lg:w-1/2">
          <div class="title mt-12 mb-4">เข้าสู่ระบบ</div>
          <label class="mb-2">
            <b>อีเมล</b>
          </label>
          <div class="mb-1"></div>
          <input
            class="w-full"
            type="email"
            placeholder="อีเมล"
            v-model="email"
          />

          <div class="mb-4">
            <div v-for="(item, index) in validatesEmail" :key="index">
              <div class="validate" v-if="item.param === 'email'">
                {{ item.msg }}
              </div>
            </div>
          </div>

          <label>
            <b>รหัสผ่าน</b>
          </label>
          <div class="mb-1"></div>
          <div class="relative flex justify-items-end justify-end">
            <span
              class="
                absolute
                inset-y-0
                flex
                items-center
                justify-items-center justify-center
                z-10
              "
              @click="checkShowHidePassword()"
            >
              <font-awesome-icon
                class="fa-2x pr-4"
                :icon="`${iconPassword % 2 === 0 ? 'eye-slash' : 'eye'}`"
                color="#C6C6C6"
              />
            </span>
            <input
              id="passwordId"
              placeholder="รหัสผ่าน"
              type="password"
              v-model="password"
            />
          </div>

          <div class="mb-4">
            <div v-for="(item, index) in validatesPassword" :key="index">
              <div class="validate" v-if="item.param === 'password'">
                {{ item.msg }}
              </div>
            </div>
          </div>

          <div class="mb-8" align="right">
            <div class="forgotPassword">
              <router-link to="/forgotpassword"> ลืมรหัสผ่าน </router-link>
            </div>
          </div>

          <button class="mb-4" @click="login()">เข้าสู่ระบบ</button>

          <div class="register">
            คุณยังไม่มีบัญชีเข้าสู่ระบบ? กรุณา
            <font color="#FA4238" style="text-decoration-line: underline">
              <router-link to="/register"> สมัครสมาชิก </router-link>
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useAuth from "../hooks/useAuth";
export default {
  methods: {
    checkShowHidePassword() {
      this.iconPassword = this.iconPassword + 1;
      if (this.iconPassword % 2 === 0) {
        document.getElementById("passwordId").type = "text";
      } else {
        document.getElementById("passwordId").type = "password";
      }
    },
    login() {
      useAuth.login(this.email, this.password).then((response) => {
        if (response.data.status === true) {
          localStorage.setItem("@auth", response.data.access_token);
          location.reload(1);
        } else {
          // response.data.errors.forEach((element) => {
          //   if (element.param === "email") {
          //     this.validatesEmail = [element];
          //   } else if (element.param === "password") {
          //     this.validatesPassword = [element];
          //   } else if (this.validatesEmail === undefined) {
          //     this.validatesEmail = [];
          //   }
          // });
        }
      });
    },
  },
  setup() {
    const email = ref(null);
    const password = ref(null);
    const iconPassword = ref(1);
    const validates = ref([]);
    const validatesEmail = ref([]);
    const validatesPassword = ref([]);
    return {
      email,
      password,
      iconPassword,
      validates,
      validatesEmail,
      validatesPassword,
    };
  },
};
</script>

<style scoped>
.image {
  width: 50px !important;
  height: 50px !important;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  /* identical to box height */
  text-align: center;
  font-weight: bold;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

.forgotPassword {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fa4238;
  text-decoration-line: underline;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

button {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  background: #573c81;
  color: white;
  border-radius: 5px;
  width: 100%;
  height: 50px;
}

.register {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #242424;
}

.container {
  font-family: KanitFont;
}
</style>